<!-- 个人中心 -->
<template>
  <div id="RefundApplication">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('refundApplication.title')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <van-row type="flex" justify="center">
          <van-col span="15" style="text-align: center" @click="handleCreate">
            <img :src="imageL[0]" alt="" class="imgAdd">
            <span style="font-size:16px;">{{ $t('refundApplication.addNew') }}</span>
          </van-col>
        </van-row>
        <div v-for="item in payCodeList" :key="item.id">
          <div v-if="item.accountType === '01'" class="payway" @click="handleUpdate(item)">
            <img :src="imageL[1]" style="width:40px;float: left;">
            <div style="float: left;">
              <p>
                {{ $t('main.name') }}:<span>{{ item.accountName }}</span>
                <br>
                {{ $t('main.account') }}:<span>{{ item.accountNo }}</span>
              </p>
            </div>
            <van-icon name="arrow" color="#E4E4E4" style="float: right;" />
          </div>
          <div v-if="item.accountType === '02'" class="payway" @click="handleUpdate(item)">
            <img :src="imageL[2]" style="width:40px;float: left;">
            <div style="float: left;">
              <p>
                {{ $t('main.name') }}:<span>{{ item.accountName }}</span>
                <br>
                {{ $t('main.account') }}:<span>{{ item.accountNo }}</span>
              </p>
            </div>
            <van-icon name="arrow" color="#E4E4E4" style="float: right;" />
          </div>
          <div v-if="item.accountType === '03'" class="payway" @click="handleUpdate(item)">
            <img :src="imageL[3]" style="width:40px;float: left;">
            <div style="float: left;">
              <p>
                {{ $t('main.name') }}:<span>{{ item.accountName }}</span>
                <br>
                {{ $t('main.account') }}:<span>{{ item.accountNo }}</span>
              </p>
            </div>
            <van-icon name="arrow" color="#E4E4E4" style="float: right;" />
          </div>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { getPersonCenterAccountList } from '@/api/personCenter'
import store from '@/store'
import { Toast } from 'vant'
export default {
  name: 'RefundApplication',
  data() {
    return {
      form: {
        oldPass: '',
        newPass: '',
        confirmPass: '',
        accountNum: '',
        schoolCode: '',
        dataSource: '',
        userId: ''
      },
      imageL: [
        require('../../assets/newImages/add.png'),
        require('../../assets/newImages/alipay.png'),
        require('../../assets/newImages/wechatpay.png'),
        require('../../assets/newImages/unionpay.png'),
        require('../../assets/newImages/usersuggestion.png'),
        require('../../assets/newImages/user.png'),
        require('../../assets/newImages/refound.png')
      ],
      payCodeList: []
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.manageAccount()
  },
  methods: {
    // 增加
    handleCreate() {
      const item = {}
      item.edit = false
      this.$router.push({ path: '/refundApplicationEdit', query: { payWayitem: item }})
    },
    // 修改
    handleUpdate(item) {
      item.edit = true
      this.$router.push({ path: '/refundApplicationEdit', query: { payWayitem: item }})
    },
    // 管理账户
    manageAccount() {
      getPersonCenterAccountList().then(response => {
        this.payCodeList = response.data
      }).catch(error => {
        Toast.fail(error.message)
      })
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/personCenter' })
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
      store.dispatch('user/logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>
<style scoped lang="scss">
#RefundApplication {
  .van-cell {
  line-height: 30px;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
.titleAdd{
  text-align: center;
  font-size:14px;
}
.titleDiv{
  display: inline-block;
  font-size:14px;
}
.imgAdd{
  width: 18px;
  margin: -3px 0;
}
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:32px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
.payway{
  background: #FFFFFF;
  height: 80px;
  padding: 20px;
  margin: 10px 0;
  p{
    padding-left:20px;
    border-left:2px solid #E4E4E4;
    color:#7F7F7F;
    line-height: 20px;
    text-align: left;
    margin-left: 20px;
    font-size:14px;
    span{
    color:#2E2E2E
    }
  }
}

</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
